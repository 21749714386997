//引入VueRouter
import VueRouter from 'vue-router'
import JYIndex from '../pages/JYIndex.vue'
import JYAbout from '../pages/JYAbout.vue'
// import JYConstruction from '../pages/JYConstruction'
// import JYContact from '../pages/JYContact'
// import JYCutrue from '../pages/JYCutrue'
// import JYIndustry from '../pages/JYIndustry'
// import JYNews from '../pages/JYNews'
// import JYProduct from '../pages/JYProduct'
// import JYTalent from '../pages/JYTalent'
// Vue.use(Router);
export default new VueRouter({
	mode: 'history',
	routes: [{
			path: '/cutrue',
			component: JYIndex
		},
		{
			path: '/',
			component: JYIndex
		},
		{
			path: '/index',
			component: JYIndex
		},
		{
			path: '/about',
			component: JYAbout
		},
		{
			path: '/construction',
			component: JYIndex
		},
		{
			path: '/contact',
			component: JYIndex
		},

		{
			path: '/industry',
			component: JYIndex
		},
		{
			path: '/news',
			component: JYIndex
		},
		{
			path: '/product',
			component: JYIndex
		},
		{
			path: '/talent',
			component: JYIndex
		},


	]
})