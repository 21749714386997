<template>
  <div class="headerH">
    <div class="header">
      <img src="../assets/logo-01-.png" alt="" class="logo" />
      <div class="search">
        <input type="text" />
        <button class="searchButton el-icon-search"></button>
      </div>
      <ul>
        <li>
          <a href="">加入我们</a>
        </li>
        <li>
          <a href="">收藏本页</a>
        </li>
        <li>
          <a href="">手机版</a>
        </li>
        <li>
          <a href="">English</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "JYHeader",
};
</script>

<style scoped>
.headerH {
  width: 100%;
  height: 60px;
  background-color: #fff;
}
.header {
  width: 1200px;
  height: 60px;
  margin: 0 auto;
  background-color: white;
}
.logo {
  height: 60px;
  float: left;
  cursor: pointer;
}
ul {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  float: right;
}

li {
  line-height: 60px;
  font-size: 12px;
  color: #9e9e9e;
  float: left;
  padding-right: 15px;
}

li a {
  text-decoration: none;
  line-height: 60px;
  font-size: 12px;
  color: #9e9e9e;
}

li a:hover {
  color: #5e5d5d;
}

.search {
  margin: 15px 15px;
  background-color: whitesmoke;
  height: 30px;
  width: 250px;
  float: right;
}
.searchButton {
  cursor: pointer;
  float: right;
  color: white;
  height: 30px;
  width: 50px;
  border: none;
  background-color: #ffb200;
}
.searchButton:hover {
  background-color: #fcc23d;
}
.search input {
  border: 1px solid #ccc;
  width: 194px;
  height: 26px;
}
</style>