<template>
	<div class="content">
		<div class="w">
			<h2>新闻动态</h2>
			<p class="introduce">
				聚焦乘风新闻，熟悉行业动态，第一时间了解您身边的乘风资讯。
			</p>
			<div class="cardBox">
				<div class="card1">
					<p>负责任矿旷物采购尽职调查报告</p>
				</div>
				<div class="card2">
					<p>负责任矿旷物采购尽职调查报告</p>
				</div>
				<div class="card3">
					<p>负责任矿旷物采购尽职调查报告</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "JYIndexContentSix",
	};
</script>

<style scoped>
	.content {
		margin-top: -20px;
		width: 100%;
		height: 600px;
		background: url("http://mc.gygkhd.top:7000/picgo/Content-4.png") no-repeat 0px 0px;
		background-size: 100% 800px;
		background-color: #fff;
	}

	.w {
		/* display: flex; */
		width: 1200px;
		margin: 0 auto;
		/* justify-content: space-around; */
	}

	h2 {
		padding-top: 48px;
		margin: 0;
		font-size: 48px;
		text-align: center;
	}

	.introduce {
		margin-top: 48px;
		text-align: center;
		color: #9e9e9e;
	}

	.cardBox {
		margin-top: 70px;
		display: flex;
		justify-content: space-around;
	}

	.card1 {
		position: relative;
		width: 320px;
		height: 240px;
		background: url("../assets/Content-6-1.jpg") no-repeat 0px 0px;
		background-size: 100% 100%;
	}

	.card2 {
		position: relative;
		width: 320px;
		height: 240px;
		background: url("../assets/Content-6-2.jpg") no-repeat 0px 0px;
		background-size: 100% 100%;
	}

	.card3 {
		position: relative;
		width: 320px;
		height: 240px;
		background: url("../assets/Content-6-3.jpg") no-repeat 0px 0px;
		background-size: 100% 100%;
	}

	.cardBox p {
		width: 320px;
		margin: 0;
		bottom: 0px;
		position: absolute;
		background-color: #000;
		opacity: 50%;
		color: #fff;
		text-align: center;
		font-size: 14px;
		line-height: 24px;
	}
</style>