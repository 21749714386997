import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import router from './router'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
	render: h => h(App),
	router: router,
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app')