import { render, staticRenderFns } from "./JYIndex.vue?vue&type=template&id=21b15d7c&scoped=true&"
import script from "./JYIndex.vue?vue&type=script&lang=js&"
export * from "./JYIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b15d7c",
  null
  
)

export default component.exports