<template>
	<div class="footer">
		<div class="w">
			<ul>
				<li> 赣ICP备2024026685号-1
				</li>
				<li>版权所有@金屹有色金属股份有限公司</li>
				<li class="lastli">赣ICP备2024026685号</li>
			</ul>
		</div>
		<div class="ul">联系方式:冯小姐137-9879-6202</div>
	</div>
</template>

<script>
	export default {
		name: "JYFooter",
	};
</script>

<style scoped>
	.footer {
		background-color: #444;
		width: 100%;
		height: 100px;
	}

	.w {
		width: 1200px;
		height: 50px;
		margin: 0 auto;
	}

	ul {
		width: 1200px;
		height: 50px;
		/* background-color: #fff; */
		margin: 0 auto;
	}

	li {
		float: left;

		margin-top: 10px;
		padding: 0px 10px;
		font-size: 14px;
		color: #fff;
		border-right: #fff solid 1px;
	}

	.ul {
		width: 100%;
		font-size: 14px;
		color: #fff;
		margin: 0 auto;
		text-align: center;
	}

	ul:first-child {
		padding-left: 250px;
	}

	.lastli {
		border-right: #fff solid 0px;
		/* background-color: #fff; */
	}
</style>