<template>
	<div>
		<JYHeader />
		<JYNav />

		<JYFooter />
	</div>
</template>

<script>
	import JYHeader from "./components/JYHeader.vue";
	import JYNav from "./pages/JYNav.vue";
	import JYFooter from "./components/JYFooter.vue";

	export default {
		name: "App",
		components: {
			JYHeader,
			JYNav,
			JYFooter
		},
		metaInfo: {
			title: '江西金屹有色铜业',
			meta: [{
					name: 'keywords',
					content: '铜业,金屹有色,铜线金屹有色,江西,镀锡铜线,绞线,铜线,有色金属'
				},
				{
					name: 'description',
					content: '公司主要生产铜线、镀锡铜线、绞线等，现公司有大拉机、中拉机、小拉机、退火机、退火镀锡机等多台先进的生产设备，同时拥有高科技、高素质的技术人员、生产管理人员，通过我们精湛的生产技术、完善的生产工艺、淋漓尽致的发挥，让每一位客户对产品的质量满意、放心。'
				}
			]
		},
	};
</script>

<style>
	body {
		padding: 0;
		margin: 0;
		background-color: #f4f4f4;
	}

	li {
		list-style-type: none;
	}
</style>