<template>
	<img src="../assets/contentLogo-1.png" alt="" />
</template>

<script>
	export default {
		name: "JYIndexContentOne",
	};
</script>

<style scoped>
	img {
		margin-bottom: 0px;
		width: 100%;
		height: 600px;
	}
</style>