<template>
	<div class="content">
		<div class="w">
			<h2>定制流程·合作流程</h2>
			<div class="cardBox">
				<img src="../assets/Content-4-1.png" alt="" />
				<img src="../assets/Content-4-2.png" alt="" />
				<img src="../assets/Content-4-3.png" alt="" />
				<img src="../assets/Content-4-4.png" alt="" />
				<img src="../assets/Content-4-5.png" alt="" />
			</div>
			<div class="button">联系方式：冯小姐137-9879-6202</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "JYIndexContentThree",
	};
</script>

<style scoped>
	.content {
		margin-top: -20px;
		width: 100%;
		height: 600px;
		background: url("../assets/Content-4.png") no-repeat 0px 0px;
		background-size: 100% 800px;
		background-color: #fff;
	}

	.w {
		width: 1200px;
		height: 600px;
		margin: 0 auto;
		/* background-color: #fff; */
	}

	h2 {
		padding-top: 48px;
		text-align: center;
		font-size: 48px;
		margin: 0;
		color: #8a8a8a;
	}

	.cardBox {
		margin-top: 50px;
		display: flex;
		width: 1200px;
		justify-content: space-around;
	}

	img {
		width: 200px;
		height: 225px;
	}

	img:hover {
		cursor: pointer;
	}

	.button {
		text-align: center;
		line-height: 80px;
		border-radius: 40px;
		width: 520px;
		height: 80px;
		background-color: #fbc803;
		margin: 0 auto;
		color: #1e1e1e;
		font-size: 28px;
		font-weight: 500;
		margin-top: 40px;
	}
</style>