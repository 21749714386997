<template>
	<div>
		<JYIndexContentOne />
		<JYIndexContentTwo />
		<JYIndexContentThree />
		<JYIndexContentFour />
		<JYIndexContentFive />
		<JYIndexContentSix />
	</div>
</template>

<script>
	import JYIndexContentOne from "../components/JYIndexContentOne.vue";
	import JYIndexContentTwo from "../components/JYIndexContentTwo.vue";
	import JYIndexContentThree from "../components/JYIndexContentThree.vue";
	import JYIndexContentFour from "../components/JYIndexContentFour.vue";
	import JYIndexContentFive from "../components/JYIndexContentFive.vue";
	import JYIndexContentSix from "../components/JYIndexContentSix.vue";

	export default {
		name: "JYIndex",
		components: {
			JYIndexContentOne,
			JYIndexContentTwo,
			JYIndexContentThree,
			JYIndexContentFour,
			JYIndexContentFive,
			JYIndexContentSix,
		},
	}
</script>

<style scoped>
</style>