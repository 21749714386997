<template>
	<div>
		<div class="w">
			<img src="../assets/aboutLogo.png" alt="" />
			<div class="introduce">
				<p>
					江西金屹有色金属有限公司，2017年7月13日成立于江西、鹰潭，地处江西省鹰潭市余江县工业园区。
				</p>
				<p>
					公司主要生产铜线、镀锡铜线、绞线等，现公司有大拉机、中拉机、小拉机、退火机、退火镀锡机等多台先进的生产设备，同时拥有高科技、高素质的技术人员、生产管理人员，通过我们精湛的生产技术、完善的生产工艺、淋漓尽致的发挥，让每一位客户对产品的质量满意、放心。
				</p>
				<p>
					公司切实秉承“诚信、创新、科技”的精神；确立了“以客户为中心，以管理求发展，以科技创造未来”的质量方针；追求一流的品质、一流的服务，确保产品高档优质，高效的现代化内部管理系统与手段、专业的技术人员、高素质的管理人员、先进的设备以及我们以满足客户要求为公司使命的经营理念。公司现已实行ISO9001:2015国际标准质量管理体系。没有最好，只有更好！我们的承诺是永远不变的！质量、信誉、科技是我们真诚希望与新老客户携手共进，共创美好未来！
				</p>
				<span><a>了解更多</a></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "JYAbout",
		components: {},
	};
</script>

<style scoped>
	.w {
		width: 1200px;
		height: 1000px;
		margin: 0 auto;
		/* background-color: #fff; */
	}

	img {
		display: block;
		width: 300px;
		height: 200px;
		margin: 0px auto;
		margin-top: 50px;
		border: #ffb200 solid 5px;
	}

	.introduce {
		width: 970px;
		height: 510px;
		margin: 0px auto;
		margin-top: 40px;
	}

	p {
		text-indent: 2em;
		color: #767676;
		font-size: 20px;
		line-height: 50px;
	}

	span {
		color: #fff;
		font-size: 20px;
		text-align: center;
		line-height: 50px;
		display: block;
		height: 50px;
		width: 260px;
		border: #ffb200 solid 2px;
		margin: 0 auto;
		margin-top: 50px;
		background-color: #ffb200;
		border-radius: 10px;
	}

	span:hover {
		background-color: #ffb300d5;
		cursor: pointer;
	}
</style>