<template>
	<div class="content">
		<div class="w">
			<div class="font">
				<h2>产品介绍</h2>
				<p>
					主要产品有：抗氧化镀锡铜线、抗氧化镀锡绞线、裸铜线
					所有相关产品均通过ISO9001 质量认证
				</p>
			</div>
			<div class="cardDiv">
				<div @click="gotoProduct" class="card">
					<img src="../assets/copper-1.png" alt="" />
					<p>裸铜线</p>
				</div>
				<div @click="gotoProduct" class="card">
					<img src="../assets/copper-1.png" alt="" />
					<p>裸铜线</p>
				</div>
				<div @click="gotoProduct" class="card">
					<img src="../assets/copper-1.png" alt="" />
					<p>裸铜线</p>
				</div>
				<div @click="gotoProduct" class="card">
					<img src="../assets/copper-1.png" alt="" />
					<p>裸铜线</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import router from "@/router";
	export default {
		name: "JYIndexContentThree",
		methods: {
			gotoProduct() {
				this.$router.replace("/product");
			},
		},
	};
</script>

<style scoped>
	.w {
		width: 1200px;
		height: 600px;
		margin: 0 auto;
		/* background-color: #fff; */
	}

	.content {
		margin-top: -20px;
		width: 100%;
		height: 600px;
		background: url("../assets/Content-3.png") no-repeat 0px 0px;
		background-size: 100% 150%;
		background-color: #fff;
	}

	h2 {
		padding-top: 20px;
		font-weight: 400;
		text-align: center;
		color: #fff;
		font-size: 48px;
		margin-top: 0;
		margin-bottom: 30px;
	}

	h2:hover {
		cursor: pointer;
	}

	.font p {
		color: #fff;
		text-align: center;
		font-size: 20px;
	}

	.font {
		margin-top: 0;
	}

	.cardDiv {
		display: flex;
		margin-top: 30px;
		width: 1200px;
		height: 350px;
		/* background-color: #fff; */
		justify-content: space-around;
	}

	.card {
		/* float: ; */
		width: 260px;
		height: 350px;
		background-color: #fff;
	}

	.card img {
		width: 260px;
	}

	.card p {
		margin: 4px 0;
		text-align: center;
		font-size: 24px;
		color: #414141;
	}

	.card:hover {
		cursor: pointer;
	}
</style>