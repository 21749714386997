<template>
  <div>
    <div class="nav">
      <div class="w">
        <ul>
          <li>
            <router-link active-class="active" to="/" exact>首页</router-link>
          </li>
          <li>
            <router-link active-class="active" to="/about"
              >关于我们</router-link
            >
          </li>
          <li>
            <router-link active-class="active" to="/news">新闻中心</router-link>
          </li>
          <!-- <li>
            <router-link active-class="active" to="/wenhua "
              >公司文化
            </router-link>
          </li> -->
          <li>
            <router-link active-class="active" to="/cutrue"
              >公司文化</router-link
            >
          </li>
          <li>
            <router-link active-class="active" to="/product"
              >产品与服务</router-link
            >
          </li>

          <li>
            <router-link active-class="active" to="/industry"
              >产业发展</router-link
            >
          </li>
          <li>
            <router-link active-class="active" to="/construction"
              >党建工作</router-link
            >
          </li>
          <li>
            <router-link active-class="active" to="/talent"
              >人才招牌</router-link
            >
          </li>
          <li>
            <router-link active-class="active" to="/contact"
              >联系我们</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "JYNav",
};
</script>

<style scoped>
.nav {
  background-color: #ffb200;
  height: 50px;
}
.w {
  width: 1200px;
  height: 50px;
  margin: 0 auto;
  /* background-color: #fff; */
}
ul {
  width: 1200px;
  margin: 0px;
}
li {
  margin-top: 12px;
  /* border-right: #fff solid 1px; */
  font-size: 18px;
  color: #fff;
  float: left;
  padding: 0px 26px;
  /* line-height: 50px; */
}

li a {
  text-decoration: none;
  font-size: 18px;
  color: #fff;
}
li a:hover {
  color: #fffb00;
}
.active {
  color: #fffb00;
}
</style>