<template>
	<div class="content">
		<div class="w">
			<div class="card">
				<i class="el-icon-success"></i>
				<p>质量保证</p>
			</div>
			<div class="card">
				<i class="el-icon-s-custom"></i>
				<p>专业团队</p>
			</div>
			<div class="card">
				<i class="el-icon-phone"></i>
				<p>客服保障</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "JYIndexContentFive",
	};
</script>

<style scoped>
	.content {
		margin-top: -20px;
		width: 100%;
		height: 600px;
		background: url("../assets/Content-5.png") no-repeat 0px 0px;
		background-size: 100% 1200px;
		background-color: #fff;
	}

	.w {
		display: flex;
		width: 1200px;
		margin: 0 auto;
		justify-content: space-around;
	}

	.card {
		margin-top: 175px;
		width: 250px;
		height: 300px;
		/* background-color: #fff; */
	}

	.card i {
		display: block;
		text-align: center;
		color: #fff;
		font-size: 125px;
		margin: 0 auto;
	}

	.card p {
		margin-top: 30px;
		color: #fff;
		font-size: 32px;
		text-align: center;
	}
</style>