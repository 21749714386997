import { render, staticRenderFns } from "./JYIndexContentOne.vue?vue&type=template&id=1bd736cf&scoped=true&"
import script from "./JYIndexContentOne.vue?vue&type=script&lang=js&"
export * from "./JYIndexContentOne.vue?vue&type=script&lang=js&"
import style0 from "./JYIndexContentOne.vue?vue&type=style&index=0&id=1bd736cf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd736cf",
  null
  
)

export default component.exports